import $ from 'jquery';
import FormUpdate from '../../components/formUpdate';
import I18N from 'core/i18n';
import Numbers from 'core/numbers';

const DEAL_DISCOUNT_TYPE_AMOUNT = '1';
const DEAL_DISCOUNT_TYPE_PERCENT = '2';

class CreateDiscountModal {
  constructor($modal) {
    this.$modal = $modal;
    this.$units = $modal.find('#SelectedUnits');
    this.$discountType = $modal.find('#Type');

    const url = this.$units.attr('data-calculate-discount-url');
    this.$discountUpdateForm = new CalculateDiscountFormUpdate(this.$units, url);

    this.$percentControl = new PercentControl($modal, this.$discountUpdateForm);
    this.$amountControl = new AmountControl($modal, this.$discountUpdateForm);

    this.init();
    this.bindEvents();
  }

  init() {
    this.handleTypeChange(this.$discountType.val(), false);
  }

  bindEvents() {
    this.$units.on('change', () => this.$discountUpdateForm.handle());
    this.$discountType.on('change', event => this.handleTypeChange(event.target.value));
    this.$modal.on('rp-form-update:updated', () => {
      this.handleHelpText(this.$amountControl.$fieldWithVatHelpText, this.$amountControl.$fieldWithVat.val());
      this.handleHelpText(this.$amountControl.$fieldWithoutVatHelpText, this.$amountControl.$fieldWithoutVat.val());
      this.handleHelpText(this.$percentControl.$percentHelpText, this.$percentControl.$fieldPercent.val());
    });
  }

  handleTypeChange(value, update = true) {
    if (value === DEAL_DISCOUNT_TYPE_AMOUNT) {
      this.$amountControl.show(true);
      this.$percentControl.show(false);
    }

    if (value === DEAL_DISCOUNT_TYPE_PERCENT) {
      this.$percentControl.show(true);
      this.$amountControl.show(false);
    }

    if (update) {
      this.$discountUpdateForm.handle();
    }
  }

  handleHelpText($helpText, value) {
    $helpText.empty();

    if (value && Numbers.parse(value) < 0) {
      $helpText.append(`<span class="text-warning">${I18N.t('deal_discount_action_create_info_negative')}</span>`);
    }
  }
}

class CalculateDiscountFormUpdate extends FormUpdate {
  handleUpdate(values) {
    super.handleUpdate(values, false); // prevent change to avoid endless update loop of amount with VAT / without VAT
    this.$form.trigger('rp-form-update:updated');
  }
}

class PercentControl {
  constructor($modal, $formUpdate) {
    this.$formUpdate = $formUpdate;

    this.$fieldsGroup = $modal.find('.deal-discount-percent');
    this.$fieldPercent = $modal.find('#Percentage');
    this.$percentHelpText = this.$fieldPercent.closest('.form-group').find('.form-help');

    this.bindEvents();
  }

  bindEvents() {
    this.$fieldPercent.on('change', () => this.$formUpdate.handle());
  }

  show(show) {
    this.$fieldsGroup.hidden(!show);
    this.$fieldPercent.disabled(!show);
  }
}

class AmountControl {
  constructor($modal, $formUpdate) {
    this.$formUpdate = $formUpdate;

    this.$fieldsGroup = $modal.find('.deal-discount-amount');
    this.$fieldWithVat = $modal.find('#amount-with-vat');
    this.$fieldWithVatHelpText = this.$fieldWithVat.closest('.form-group').find('.form-help');

    this.$fieldWithoutVat = $modal.find('#amount-without-vat');
    this.$fieldHasAmountWithoutVat = $modal.find('#has-amount-without-vat');
    this.$fieldWithoutVatHelpText = this.$fieldWithoutVat.closest('.form-group').find('.form-help');

    this.$lockedButton = $modal.find('#locked-button');
    this.$unlockedButton = $modal.find('#unlocked-button');
    this.$fieldUnlocked = $modal.find('#amount-unlocked');

    this.init();
    this.bindEvents();
  }

  init() {
    this.handleUnlock(this.$fieldUnlocked.val() === 'true', false);
  }

  bindEvents() {
    this.$lockedButton.on('click', () => this.handleUnlock(!this.unlocked));
    this.$unlockedButton.on('click', () => this.handleUnlock(!this.unlocked));
    this.$fieldWithVat.on('change', () => this.handleFieldWithVat());
    this.$fieldWithoutVat.on('change', () => this.handleFieldWithoutVat());
  }

  show(show) {
    this.$fieldsGroup.hidden(!show);
    this.$fieldsGroup.disabled(!show);
  }

  handleUnlock(unlocked, update = true) {
    this.unlocked = unlocked;
    this.$fieldUnlocked.val(this.unlocked);

    this.toggleButton(this.$lockedButton, !this.unlocked);
    this.toggleButton(this.$unlockedButton, this.unlocked);

    if (update) {
      this.$formUpdate.handle();
    }
  }

  toggleButton(button, show) {
    button.hidden(!show);
    button.disabled(!show);
  }

  handleFieldWithVat() {
    this.$fieldHasAmountWithoutVat.val(false);
    this.$formUpdate.handle();
  }

  handleFieldWithoutVat() {
    this.$fieldHasAmountWithoutVat.val(true);
    this.$formUpdate.handle();
  }
}

$(() => {
  $(document).on('rp-modal:loaded', (event, data) => {
    if (data.$root.is("#create-discount-modal")) {
      new CreateDiscountModal(data.$root);
    }
  });
});
