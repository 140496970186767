import $ from 'jquery';

class UserSettingsSalesAgent {
  constructor($div) {
    this.$div = $div;
    this.$showSwitch = this.$div.find('#is-sales-agent');
    this.isDisabled = !this.$showSwitch.is(':checked');
    this.$content = this.$div.find('input').not('#is-sales-agent');

    this.bindEvents();
  }

  bindEvents() {
    this.$showSwitch.on('change', () => this.disableContent(!this.isDisabled));
  }

  disableContent(disable) {
    this.$content.disabled(disable);
    this.isDisabled = disable;
  }
}

$(() => {
  $(document).on('rp-partial:loaded', (event, data) => {
    const salesAgentDiv = data.$root.find('#rp-sales-agent');
    if (salesAgentDiv.exists()) {
      new UserSettingsSalesAgent(salesAgentDiv);
    }
  });
});